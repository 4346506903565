import './AboutSupport.css'
import Button from '../Button'

export default function AboutSupport() {
  return (
    <div className="about-support-container bg-nature">
      <h2 className="resize-header">
        HONK! is coming to Portland, OR
      </h2>

      <a href="https://www.paypal.com/donate?hosted_button_id=JYK2WXZHEG7NC" 
          target="_blank"
          rel="noreferrer"
        >
          <Button background="brass" text="SUPPORT HONK!" />
        </a>
    </div>
  )
}