import './HomeSupport.css'
import Button from '../Button'
import homeSupportSVG from '../../media/svg/Honk_People_Web_footer.svg'

export default function HomeSupport() {
  return (
    <div className="bg-community home-support-container">
      <h2>
        HONK! is coming to Portland, OR
      </h2>
      <p>
        Support us by donating online
      </p>
      <a href="https://www.paypal.com/donate?hosted_button_id=JYK2WXZHEG7NC"
        target="_blank"
        rel="noreferrer"
      >
        <Button background="nature" text="SUPPORT HONK!" />
      </a>
      <img alt="" src={homeSupportSVG} />
    </div>
  )
}