import NavBar from '../../components/NavBar';
import FooterBar from '../../components/FooterBar';

import FestivalHero from '../../components/FestivalHero';
import FestivalBandList from '../../components/FestivalBandList';

import ConnectForm from "../../components/ConnectForm";

const bandListPast = [
  {
    "NAME": "8-Bit Brass Band",
    "CITY_STATE": "Seattle, WA"
  },
  {
    "NAME": "Analog Brass",
    "CITY_STATE": "Bellingham, WA"
  },
  {
    "NAME": "Arrasta!",
    "CITY_STATE": "Portland, OR"
  },
  {
    "NAME": "Artesian Rumble Orchestra",
    "CITY_STATE": "Olympia, WA"
  },
  {
    "NAME": "Beat Crunchers",
    "CITY_STATE": "Eugene, OR"
  },
  {
    "NAME": "Brassless Chaps",
    "CITY_STATE": "Portland, OR"
  },
  {
    "NAME": "Bug Complex",
    "CITY_STATE": "Portland, OR"
  },
  {
    "NAME": "Chaotic Noise Marching Corps",
    "CITY_STATE": "Seattle, WA"
  },
  {
    "NAME": "Cimarrona La Original Domingueña",
    "CITY_STATE": "Costa Rica"
  },
  {
    "NAME": "Couch Brass",
    "CITY_STATE": "Portland, OR"
  },
  {
    "NAME": "Filthy FemCorps",
    "CITY_STATE": "Seattle, WA"
  },
  {
    "NAME": "Kef",
    "CITY_STATE": "Eugene, OR"
  },
  {
    "NAME": "Last Regiment of Syncopated Drummers -",
    "CITY_STATE": "Portland, OR"
  },
  {
    "NAME": "Madband",
    "CITY_STATE": "Eugene, OR"
  },
  {
    "NAME": "Neon Brass Party",
    "CITY_STATE": "Seattle, WA"
  },
  {
    "NAME": "One Love",
    "CITY_STATE": "Seattle, WA"
  },
  {
    "NAME": "Portland Samba",
    "CITY_STATE": "Portland, OR"
  },
  {
    "NAME": "Portland Taiko",
    "CITY_STATE": "Portland, OR"
  },
  {
    "NAME": "Power Pep Band",
    "CITY_STATE": "Portland, OR"
  },
  {
    "NAME": "Tacomarama",
    "CITY_STATE": "Tacoma, WA"
  },
  {
    "NAME": "The Beat Goes On",
    "CITY_STATE": "Portland, OR"
  },
  {
    "NAME": "The Fighting Instruments of Karma Marching Chamber Band/Orchestra",
    "CITY_STATE": "Eugene, OR"
  },
  {
    "NAME": "The Jesters",
    "CITY_STATE": "Portland, OR"
  },
  {
    "NAME": "Unpresidented Brass Band",
    "CITY_STATE": "Portland, OR"
  }
]

export default function Festival() {
  return (
    <div className="App">
      <header>
        <NavBar />
      </header>

      <main>
        <FestivalHero />
        <ConnectForm />
        <FestivalBandList header="Honk! PDX Past Bands" bandList={bandListPast} />
      </main>

      <footer>
        <FooterBar />

      </footer>
    </div>
  )
}