import './SponsorsHero.css'
import MetbaPNG from '../../media/png/metba-logo.jpg'
import MrpPNG from '../../media/png/mr-plywood.png'
import SponsorHeroSVG from '../../media/svg/Honk_People_Web_header_supporters.svg'
import TrimetPNG from '../../media/png/TriMet-Slogan-Web.png'

export default function SponsorsHero() {
  return (
    <div className="sponsor-hero-container">
      <h1 className="hidden">
        Honk! Supporters
      </h1>
      <img alt="" className="bg-community" src={SponsorHeroSVG} />
      <div className="sponsor-card">
        <img alt="Family Style Customer Service
Delivery Service • Custom Cutting • Special Orders
7609 S. E. Stark Sreet • Portland, OR 97215
Phone: 503.254.7387 • Fax: 503.254.7663
www.mrolywoodinc.com" className="sponsor-logo" src={MrpPNG} />
        <span className="sponsor-title">
          <h2>Mr Plywood Building Materials</h2>
        </span>
      </div>
      <div className="sponsor-card">
        <img alt="Montavilla East Tabor Business Association" className="sponsor-logo" src={MetbaPNG} />
        <span className="sponsor-title">
          <h2>Montavilla/East Tabor Business Association (METBA)</h2>
        </span>
      </div>
      <div className="sponsor-card">
        <img alt="TriMet" className="sponsor-logo" src={TrimetPNG} />
        <span className="sponsor-title">
          <h2>TriMet</h2>
        </span>
      </div>
    </div>
  )
}