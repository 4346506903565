import './ConnectHero.css'
import ConnectForm from '../ConnectForm'
import connectHeroSVG from '../../media/svg/Honk_People_Web_small_header.svg'

export default function ConnectHero() {
  return (
    <div className='bg-community connect-hero-container'>
      <img alt="" className="bg-community" src={connectHeroSVG} />
      <h1 className="resize-header">Honk! is coming to Portland in 2025!</h1>
      <p>Find us on Facebook, Instagram and TikTok <span className="handle">@honkpdx</span></p>
      <ConnectForm />
      <h2>Contact Us</h2>
      <p className="general-info">For general information: <em>honkportland@gmail.com</em></p>
    </div>
  )
}