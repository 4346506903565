import './FestivalHero.css'
import FestivalLogoSVG from '../../media/svg/Honk_People_Web_small_header.svg'

export default function FestivalHero() {

  return (
    <div className="festival-hero-container">
      <div className="bg-community">
        <img alt="Honk PDX" src={FestivalLogoSVG} />
        <h1 id="festival-header">HONK PDX 2025 - More information coming soon!</h1>
      </div>
    </div>
  )
}