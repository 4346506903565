import './VolunteerHero.css'
import volunteerHeroSVG from '../../media/svg/Honk_People_Web_small_header.svg'

export default function VolunteerHero() {
  return (
    <div className="volunteer-hero-container">
      <div className="bg-community">
        <img alt="" className="bg-community" src={volunteerHeroSVG} />
        <h1>Volunteer</h1>
      </div>

      <div className="location-table-container">
        <h3>For volunteer opportunities please email us at honkportland@gmail.com</h3>
      </div>
    </div>
  )
}