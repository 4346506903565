import { useState } from 'react';
import './NavBar.css'
import { Link } from "react-router-dom";

export default function NavBar() {
  const [responsive, setResponsive] = useState(false);

  function handleNavMenuButton() {
    setResponsive(!responsive)
  }

  return (
    <nav className={responsive ? "topnav responsive" : "topnav"} id="myTopnav">
      <div className='nav-container'>
        <Link to='/'>Honk! PDX</Link>
        <Link to='/festival'>Festival</Link>
        <Link to='/connect'>Connect</Link>
        <Link to='/about'>About</Link>
        <a href="https://www.paypal.com/donate?hosted_button_id=JYK2WXZHEG7NC" 
          target="_blank"
          rel="noreferrer"
        >
          Donate
        </a>
      </div>
      <button className="bg-clear icon" onClick={handleNavMenuButton}>
        {'\u2630'}
      </button>
    </nav>
  );
}